import Cookies from 'universal-cookie';

// Function to get user_id (from cookies)
export const getUserId = () => {
  const cookies = new Cookies();
  const userId = cookies.get("user_id"); 
  // console.log("Fetched user_id from cookies:", userId);  
  return userId ? userId : "guest"; // Return 'guest' if no user_id is found
};

// Function to get UTM parameters from URL
const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    source: params.get("utm_source") || "direct",
    medium: params.get("utm_medium") || "none",
    campaign: params.get("utm_campaign") || "default_campaign",
    content: params.get("utm_content") || "default_content",
  };
};

// Function to push events dynamically to GTM Data Layer
export const pushToDataLayer = (eventName, eventData = {}, excludeUTM = false) => {
  if (window.dataLayer) {
    
    let eventPayload = {
      event: eventName,
      // user_id: userId, 
      ...eventData, 
    };

    // Only include UTM parameters if excludeUTM is false
    if (!excludeUTM) {
      eventPayload = { ...eventPayload, ...getQueryParams() };
    }

    window.dataLayer.push(eventPayload);
  } else {
    console.log("DataLayer is not available");
  }
};


export const trackBannerClick = (bannerName) => {
  // const userId = getUserId(); )
  const pageName = window.location.pathname; 
  pushToDataLayer(
    "clicked_banner",
    {
      page_name: pageName,
      banner_name: bannerName,
    },
    true // Exclude UTM parameters
  );
};


export const trackCartClick = () => {
  // const userId = getUserId(); // Fetch user ID
  const pageName = window.location.pathname; 
  pushToDataLayer("clicked_cart", { page_name: pageName }, false);
};


export const trackQuickViewClick = (productName, sectionName) => {
  // const userId = getUserId(); // Fetch user ID
  const pageName = window.location.pathname; 

  pushToDataLayer(
    "clicked_quick_view",
    {
      product_name: productName,
      section_name: sectionName || "default_section",
      page_name: pageName,
    },
    true // Exclude UTM parameters
  );
};

// Handle Category Click
export const handleCategoryClick = (category) => {
  // const userId = getUserId(); 
  const pageName = "home_top_carousel"; 

  // Push event to dataLayer
  pushToDataLayer("clicked_category_from_grid", {
    // user_id: userId,  // Include user_id in category click event
    category_selected: category.category_name,
    page: pageName,
  });

  window.location.href = `/categories/${category.url}`;  
};

export const handleCardClick = (data) => {
  const productName = data?.name || "Unknown Product"; 
  const pageName = window.location.pathname;
  const cardType = data?.category || "product";  

  pushToDataLayer("clicked_card", { 
    card_type: cardType,  
    card_name: productName,  
    page_name: pageName,  
  });
};
