import React from "react";
import { Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";
import { trackBannerClick } from "../../utils/datalayerPush";

const DealToDealBanner = ({ renderMobImg, altMobImg, altWebImg, renderImg, url }) => {
 const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
 const bannerName = isMobile ? altMobImg : altWebImg;

  return (
    <NavLink to={url} onClick={() => trackBannerClick(altWebImg || altMobImg)}>
      <Container className="maxWidthContainerFluid paddingContainerFluid" fluid>
        <Row className="Big_bnrs">
          {isMobile ? (
            <img
              className="bnr_img b-curve"
              alt={altMobImg}
              src={`/Assets/${renderMobImg}`}
            />
          ) : (
            <img
              className="bnr_img b-curve"
              alt={altWebImg}
              src={`/Assets/${renderImg}`}
            />
          )}
        </Row>
      </Container>
    </NavLink>
  );
};


export default DealToDealBanner;