import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CommonHorizontalCard from "../../componets/CommonHorizontalCard";
import { Container } from "react-bootstrap";
import CommonSubHeading from "../../componets/CommonSubHeading";
import { useTranslation } from "react-i18next";

const ExcitingOffer = ({ exictingOffers }) => {
  const { t } = useTranslation();
  // console.log(exictingOffers)


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      partialVisibilityGutter: 20,
    },
    largeMobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  const colorArray = {
    0: "linear-gradient(to right, #fad98a, #f18c82)",
    1: "linear-gradient(to right, #f4bb81, #eb576c)",
    2: "linear-gradient(to right, #ea5297, #ee717b)",
    3: "linear-gradient(to right, #682774, #d65478)",
    4: "linear-gradient(to right, #fad98a, #f18c82)",
    5: "linear-gradient(to right, #f4bb81, #eb576c)",
    6: "linear-gradient(to right, #f4bb81, #eb576c)",
    7: "linear-gradient(to right, #682774, #d65478)",
    8: "linear-gradient(to right, #fad98a, #f18c82)",
    9: "linear-gradient(to right, #f4bb81, #eb576c)",
  };

  const buttonColor = {
    0: "#ffffff",
    1: "#ffffff",
    2: "#ffffff",
    3: "#ffffff",
    4: "#ffffff",
    5: "#ffffff",
    6: "#ffffff",
    7: "#ffffff",
    8: "#ffffff",
    9: "#ffffff",
  };

  const countdownStyle = {
    background: "#FD7900",
    color: "#ffffff",
  };
  
  const setPadding = "4px 8px 4px 6px";

  return (
    <>
      {exictingOffers.length > 0 && (
        <Container className="maxWidthContainerFluid excitingSection" fluid>
          <CommonSubHeading
            heading={t("home.excitingTitle")}
            title={true}
            viewAll={false}
          />
          <Carousel responsive={responsive} partialVisible={true}>
          {exictingOffers.map((each, i) => {
            // console.log(each.section_name);  // Log to check if section_name is available
            return (
              <CommonHorizontalCard
                key={i}
                data={each}
                color={colorArray[i.toString().slice(-1)]}
                buttonColor={buttonColor[i.toString().slice(-1)]}
                setWidth={true}
                countdownStyle={countdownStyle}
                setPadding={setPadding}
                section_name={each.section_name} // Passing dynamically
              />
            );
          })}
          </Carousel>
        </Container>
      )}
    </>
  );
};

export default ExcitingOffer;
